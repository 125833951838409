import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"

// Components
import SEO from "../components/SEO"

// Helpers & mock content
import { fireEvent, getRedirectLanguage } from "../utils/helpers"
import { LocaleContext } from "../components/contexts/LocaleContext"
import { isSpinURL } from "../utils/spinCodes"

// markup
const PageBuilder = ({ pageContext }) => {
  const { pathname } = useLocation()
  const [, setLocale] = useContext(LocaleContext)
  const { pageInfo, locale, slug } = pageContext
  const { navbarTheme } = pageInfo

  useEffect(() => {
    fireEvent("nav-scheme", navbarTheme)
    let code = pathname.split("/")[1]

    console.log("code testing from Redirect.js")

    if (isSpinURL(code)) {
      window.location.href = "https://scooters.spin.pm/qr/" + code
      return
    }

    if (code && /^([A-Z]{2}[0-9]+)$/.test(code)) {
      console.log("This is a redirect route: ", code)
      window.location.href = "https://qr.tier-services.io/$1"
      return
    }

    if (!locale) {
      const urlLang = getRedirectLanguage()
      setLocale(urlLang, slug)
    }
  }, [])

  return (
    <div className="relative overflow-hidden">
      <SEO title="test" description="testing" />
      <div className="w-full h-screen bg-background"></div>
    </div>
  )
}

PageBuilder.propTypes = {
  pageContext: PropTypes.shape({
    pageInfo: PropTypes.object,
    footerInfo: PropTypes.object,
    locale: PropTypes.string,
    slug: PropTypes.string,
  }),
}

export default PageBuilder
